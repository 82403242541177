import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby'
import moment from 'moment';
import { Button, createStyles, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/header';
import { INoticia } from '../components/news/interfaces';
import { containerProps, contentProps } from '../utils/constants';
import { sanitizeHtml } from '../utils/functions';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  container: containerProps,
  content: contentProps,
  box: {
    '& img': {
      width: '100%',
      height: 'auto'
    },
    [theme.breakpoints.up('md')]: {
      flex: 1,
      '& img': {
        float: 'left',
        maxWidth: 550,
        margin: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px 0`
      }
    }
  },
  boxTwo: {
    flex: '2 0 0',
  }
}));

const Noticia = ({ location }) => {
  const classes = useStyles();
  const [noticia, setNoticia] = useState<INoticia>();

  const init = () => {
    if (!location || !location.state) {
      navigate('/');
      return;
    }

    setNoticia(location.state.noticia);
  }

  useEffect(() => {
    init();
  }, []);

  if (!noticia) return (
    <>
      <Typography>Hubo un problema al cargar la información de la noticia.</Typography>

      <Button variant='contained' color='primary' onClick={() => navigate('/')}>Ir al inicio</Button>
    </>
  );

  return (
    <Layout>
      <SEO title={sanitizeHtml(noticia.titulo)} />


      <div className={classes.container}>
        <Header title={sanitizeHtml(noticia.titulo)} />

        <div className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12} container>
              <div className={classes.box}>
                <img src={`data:image/jpeg;base64,${noticia.imagen}`}></img>
                <Typography
                  className={classes.boxTwo}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(noticia.resena)
                  }}
                ></Typography>
              </div>
            </Grid>
          </Grid>
          <Divider />
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Typography variant='subtitle2'>Instituto de Seguridad Social del Estado de Tabasco</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>{moment(noticia.fecha).format('DD [de] MMMM [de] YYYY')}</Typography>
          </Grid>
        </div>
      </div>
    </Layout>
  );
};

export default Noticia;